import axios from "axios";
import { filter, flattenDepth, has, round } from "lodash";
import Slider, { SliderTooltip } from "rc-slider";
import "rc-slider/assets/index.css";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { FormGroup, Input, Label } from "reactstrap";
import Navigation from "../components/navigation/Header";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      presentCategory: [],
      questions: [],
      questions_data: [],
      result: [],
      resultPassArray: [],
      alreadyCalled: [],
      resultFetching: false,
      assessment_Id: "",
    };
    this.url = `${process.env.REACT_APP_API_IP}`;
  }

  componentDidMount = () => {
    let uri = this.url + "/assessment/get-completed-assessment";
    const options = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
      },
    };
    let stateapp = localStorage["appState"];
    let AppStateCall = JSON.parse(stateapp);
    const postData = {
      assessment_id: Number(sessionStorage["selectedAssessment"]),
    };
    axios.post(uri, postData, options).then((response) => {
      if (response.status == 200) {
        this.setState({
          assessment_Id: response.data.id,
          questions_data: response.data.data,
          resultFetching: false,
        });
        let dummyVariable = this.state.questions;
        let rdata = response.data.data;
        console.log("rrrr", rdata);
        let z = Object.keys(response.data.data);
        let ddata = [];
        z.map((key, index) => {
          ddata.push(rdata[Object.keys(rdata)[index]]);
        });
        let rdata1 = ddata;
        let dataWithKey;
        if (Object.keys(rdata).length) {
          dataWithKey = rdata1.map((row1) => {
            // return row1
            return row1.map((m) => {
              var newObject = { selectedAnswer: { Score: 0, note: "" } };
              m = { ...newObject, ...m };
              return m;
            });
          });
        }

        console.log("saad", rdata1);
        let dataWithKeyIndex = new Array([].concat(...dataWithKey));

        let dummyquest = response.data.data;
        console.log(dummyVariable, "duuuuuu");

        dummyVariable.push(dataWithKeyIndex[0]);
        this.setState({
          presentCategory: dummyquest,
          questions: dummyVariable,
        });
      }
    });

    let state = localStorage["domainState"];
    if (state) {
      let domainState = JSON.parse(state);
      let uri = this.url + "/questions/domain-wise";

      const data = {
        domain: domainState.presentDomain,
        //  assessmentType: this.props.location.state.assessmentType
      };
      const options = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
        },
      };
      this.setState({
        resultFetching: true,
      });
    }
  };

  Next = () => {
    window.scrollTo(0, 0);
    //this.unCheck();
    let state = localStorage["domainState"];

    if (state) {
      let domainState = JSON.parse(state);

      for (var i = 0; i < domainState.domains.length; i++) {
        if (domainState.domains[i].ID == domainState.nextDomain) {
          if (i <= domainState.domains.length - 1) {
            var count = 0;
            if (i + 1 == domainState.domains.length) {
              count = i;
            } else {
              count = i + 1;
            }

            let domainStatee = {
              presentDomain: domainState.nextDomain,
              nextDomain: domainState.domains[count].ID,
              domains: domainState.domains,
            };
            localStorage["domainState"] = JSON.stringify(domainStatee);
          }
        } else {
          // console.log("else")
        }
      }
    }

    let apistate = localStorage["domainState"];

    if (apistate) {
      let domainState = JSON.parse(apistate);

      let apiCalledArray = this.state.alreadyCalled;
      if (false && apiCalledArray.includes(domainState.presentDomain)) {
        this.setState({
          resultFetching: false,
        });
      } else {
        this.setState({
          resultFetching: false,
        });
      }
    }

    console.log(this.state.resultPassArray, "resultPassArray result here");
  };

  unCheck() {
    var x = document.getElementsByClassName("custom-control-input");
    var i;
    for (i = 0; i < x.length; i++) {
      x[i].checked = false;
    }
  }

  Prev = () => {
    window.scrollTo(0, 0);
    let state = localStorage["domainState"];
    if (state) {
      let domainState = JSON.parse(state);
      let domainStatee = {
        presentDomain: domainState.presentDomain - 1,
        nextDomain: domainState.presentDomain,
        previousDomain: domainState.presentDomain - 2,
        domains: domainState.domains,
      };
      localStorage["domainState"] = JSON.stringify(domainStatee);
    }

    let apistate = localStorage["domainState"];

    if (apistate) {
      let domainState = JSON.parse(apistate);

      let apiCalledArray = this.state.alreadyCalled;
      if (false && apiCalledArray.includes(domainState.presentDomain)) {
        this.setState({
          resultFetching: false,
        });
      } else {
        this.setState({
          resultFetching: false,
        });
      }
    }
  };

  getFinalEvaluation() {
    let array = this.state.questions;
    let mergedArrays = new Array([].concat(...array));
    var holder = {};
    var notesholder = {};
    let obj = mergedArrays[0];
    console.log(obj, "Answers");
    let dummyNotes = "";
    obj.forEach(function (d) {
      //let notesholder[d.Question_Category]=''
      if (notesholder.hasOwnProperty(d.Question_Category)) {
        let dummy = notesholder[d.Question_Category];
        dummy.push(
          d.Question_type != 1
            ? {
                note: d.Note,
                title: d.Note_title,
                toDisplay: d.selectedAnswer.Score >= d.Special_note_min && d.selectedAnswer.Score <= d.Special_note_max,
              }
            : {
                note: d.selectedAnswer.Note,
                title: d.selectedAnswer.Note_title,
                toDisplay: d.selectedAnswer.Score >= d.Special_note_min && d.selectedAnswer.Score <= d.Special_note_max,
              },
        );
        notesholder[d.Question_Category] = dummy;
      } else {
        notesholder[d.Question_Category] = [
          d.Question_type != 1
            ? { note: d.Note, title: d.Note_title }
            : {
                note: d.selectedAnswer.Note,
                title: d.selectedAnswer.Note_title,
              },
        ];
      }
      if (holder.hasOwnProperty(d.Question_Category)) {
        d.Question_type != 4 ? (holder[d.Question_Category] = holder[d.Question_Category] + d.selectedAnswer.Score) : (holder["array"] = holder[array][d.Question_Category].push(d.selectedAnswer.Score));
      } else {
        d.Question_type != 4
          ? (holder[d.Question_Category] = d.selectedAnswer.Score)
          : (holder["array"] = {
              [d.Question_Category]: [d.selectedAnswer.Score],
            });
      }
    });
    var obj2 = [];
    let dummyNote = [];
    console.log(holder, "holder");
    for (var prop in holder) {
      console.log(prop, "ppp");
      console.log(array, "anserarray");
      obj2.push({
        Question_Category: prop,
        notes: notesholder,
        Score: has(holder.array, prop) ? round(holder[prop] / flattenDepth(array, 5).filter((obj) => obj.Question_Category === prop && obj.Question_type != 4).length, 2) * 0.1 * (holder["array"][prop][0] > 10 ? 10 : holder["array"][prop][0]) : round(holder[prop] / flattenDepth(array, 5).filter((obj) => obj.Question_Category === prop).length, 2),
      });
    }
    this.setState({
      result: obj2,
    });
    console.log(obj2, "result ");
  }

  getChecked(data) {
    console.log(data, "sssssssss");
    let dummyVariable = this.state.questions;
    dummyVariable.map((array) => {
      array.map((innerArray) => {
        if (innerArray.selectedAnswer === data) {
          return true;
        } else return false;
      });
    });
    // return true
  }

  render() {
    let z = Object.keys(this.state.presentCategory).filter((i) =>
      i.includes(
        filter(JSON.parse(localStorage["domainState"]).domains, {
          ID: JSON.parse(localStorage["domainState"]).presentDomain,
        })[0].domain_name,
      ),
    );
    return (
      <div>
        <Navigation {...this.props} />

        <div className="height">
          <div className="container">
            {this.state.resultFetching ? (
              <div style={{ marginLeft: "400px" }}>
                <ClipLoader size={300} color={"#123abc"} loading={true} />
              </div>
            ) : (
              <div className="assessment-worship-bottom">
                <h3 className="domainName">
                  {
                    filter(JSON.parse(localStorage["domainState"]).domains, {
                      ID: JSON.parse(localStorage["domainState"]).presentDomain,
                    })[0].domain_name
                  }
                </h3>
                {Object.keys(this.state.presentCategory).length &&
                  z.map((row1) => (
                    <div style={{ pointerEvents: "none" }}>
                      <h3 className="cetagoryName">{row1.replace("_", " ")}</h3>
                      {this.state.presentCategory[row1].map((row, index) => (
                        <div className="assessment-worship-box">
                          <p>
                            <span>{row.Sequence}. </span> {row.Question_text}
                          </p>

                          <ul className="radio-style">
                            {row.Question_type == 1 ? (
                              row.answers.map((row2, index2) => (
                                <li>
                                  {console.log(row2, "qqq11")}
                                  <div className="custom-control custom-radio">
                                    {console.log(row, "row")}
                                    <input type="radio" required={true} className="custom-control-input" id={[row1] + row.ID + "_" + index2} name={[row1] + row.ID + index} checked={row.Score === row2.Score} />
                                    <label className="custom-control-label" htmlFor={[row1] + row.ID + "_" + index2}>
                                      {row2.Possible_Answer}
                                    </label>
                                  </div>
                                </li>
                              ))
                            ) : row.Question_type == 2 ? (
                              <div>
                                <Slider
                                  min={0}
                                  max={100}
                                  defaultValue={row.Score * 10}
                                  marks={{
                                    0: 0,
                                    10: 10,
                                    20: 20,
                                    30: 30,
                                    40: 40,
                                    50: 50,
                                    60: 60,
                                    70: 70,
                                    80: 80,
                                    90: 90,
                                    100: 100,
                                  }}
                                  step={10}
                                />
                              </div>
                            ) : (
                              <FormGroup>
                                <Label for="exampleEmail"></Label>
                                <Input type="number" name="percentage" id="exampleEmail" min="1" max="100" placeholder="%" defaultValue={row.Score} />
                              </FormGroup>
                            )}
                          </ul>
                        </div>
                      ))}
                    </div>
                  ))}
              </div>
            )}
          </div>

          <div className="centerButtons text-center">
            {
              /*JSON.parse(localStorage["domainState"]).presentDomain > 1*/ true && (
                <button
                  className="btn btn-small cta-primary prev-btn"
                  style={{
                    pointerEvents: JSON.parse(localStorage["domainState"]).presentDomain > 1 ? "auto" : "none",
                  }}
                  onClick={() => {
                    this.Prev();
                  }}
                >
                  Previous
                </button>
              )
            }
            {JSON.parse(localStorage["domainState"]).presentDomain < 7 && (
              <button
                className="btn btn-small cta-primary next-btn"
                onClick={() => {
                  this.Next();
                }}
              >
                Next
              </button>
            )}
            {
              <button className="btn btn-small cta-primary prev-btn" style={{ marginLeft: "15px" }} onClick={() => {}}>
                {" "}
                <Link
                  to={{
                    pathname: "/home",
                  }}
                  style={{ color: "white" }}
                >
                  Exit
                </Link>
              </button>
            }
          </div>
        </div>
      </div>
    );
  }
}
