import axios from "axios";
import { filter, flattenDepth, has, isNull, isEmpty, round } from "lodash";
import moment from "moment";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter } from "reactstrap";
import Navigation from "../components/navigation/Header";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      presentCategory: [],
      questions: [],
      questions_data: [],
      result: [],
      resultPassArray: [],
      alreadyCalled: [],
      assessmentResponse: [],
      resultFetching: false,
      assessment_Id: "",
      percentageIncomeField: "",
      checkCoupleResultsExists: false,
      popoverOpen: false,
      date_completed: "",
    };
    // this.url = "http://localhost:33333";
    this.url = `${process.env.REACT_APP_API_IP}`;
  }

  componentDidMount = () => {
    let uri = this.url + "/assessment/";
    let uriresponse = this.url + "/assessment/get-assessment-response-id";
    const options = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
      },
    };
    let stateapp = localStorage["appState"];
    let AppStateCall = JSON.parse(stateapp);
    const postData = {
      date_created: moment().format("YYYY-MM-DD hh:mm:ss"),
      user_id: AppStateCall.user,
      template: sessionStorage["templateId"],
      status: "inprogress",
      authToken: this.props.authToken,
    };
    this.setState({
      resultFetching: true,
    });
    axios.post(uri, postData, options).then((response) => {
      if (response.status == 200) {
        const postDataresponse = {
          id: response.data.id,
          authToken: this.props.authToken,
        };
        axios.post(uriresponse, postDataresponse, options).then((responsedata) => {
          if (responsedata.status == 200) {
            this.setState({
              assessmentResponse: responsedata.data.data,
            });
          }
        }); //end of call for getting whole Assessment_responses
        this.setState({
          assessment_Id: response.data.id,
          questions_data: response.data.data,
          resultFetching: false,
        });
        let dummyVariable = this.state.questions;
        let rdata = response.data.data;
        let z = Object.keys(response.data.data);
        let ddata = [];
        z.map((key, index) => {
          ddata.push(rdata[Object.keys(rdata)[index]]);
        });
        let rdata1 = ddata;
        let dataWithKey;
        if (Object.keys(rdata).length) {
          dataWithKey = rdata1.map((row1) => {
            // return row1
            return row1.map((m) => {
              var newObject = { selectedAnswer: { Score: 0, note: "" } };
              m = { ...newObject, ...m };
              return m;
            });
          });
        }

        let dataWithKeyIndex = new Array([].concat(...dataWithKey));

        let dummyquest = response.data.data;

        dummyVariable.push(dataWithKeyIndex[0]);
        this.setState({
          presentCategory: dummyquest,
          questions: dummyVariable,
        });
      }
    });

    let state = localStorage["domainState"];
    if (state) {
      let domainState = JSON.parse(state);

      const data = {
        domain: domainState.presentDomain,
      };
      const options = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
        },
      };
      this.setState({
        resultFetching: true,
      });
    }
  };

  // go to next section page in assesments
  Next = () => {
    window.scrollTo(0, 0);
    let state = localStorage["domainState"];

    if (state) {
      let domainState = JSON.parse(state);

      for (var i = 0; i < domainState.domains.length; i++) {
        if (domainState.domains[i].ID == domainState.nextDomain) {
          if (i <= domainState.domains.length - 1) {
            var count = 0;
            if (i + 1 == domainState.domains.length) {
              count = i;
            } else {
              count = i + 1;
            }

            let domainStatee = {
              presentDomain: domainState.nextDomain,
              nextDomain: domainState.domains[count].ID,
              domains: domainState.domains,
            };
            localStorage["domainState"] = JSON.stringify(domainStatee);
          }
        } else {
          //
        }
      }
    }
    let apistate = localStorage["domainState"];

    if (apistate) {
      let domainState = JSON.parse(apistate);

      let apiCalledArray = this.state.alreadyCalled;
      if (false && apiCalledArray.includes(domainState.presentDomain)) {
        this.setState({
          resultFetching: false,
        });
      } else {
        this.setState({
          resultFetching: false,
        });
      }
    }

    let uri = this.url + "/assessment/add-assessment";
    const postData = this.state.resultPassArray;

    const finalPostData = postData.map(({ assessmentResponseId, assessment, questionId, answer, score }) => ({
      assessmentResponseId,
      assessment,
      questionId,
      answer,
      score,
    }));

    const options = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
      },
    };

    axios.post(uri, finalPostData).then((response) => {});
  };

  unCheck() {
    var x = document.getElementsByClassName("custom-control-input");
    var i;
    for (i = 0; i < x.length; i++) {
      x[i].checked = false;
    }
  }

  // go to previous section page in assesments
  Prev = () => {
    window.scrollTo(0, 0);
    let state = localStorage["domainState"];

    if (state) {
      let domainState = JSON.parse(state);
      let domainStatee = {
        presentDomain: domainState.presentDomain - 1,
        nextDomain: domainState.presentDomain,
        previousDomain: domainState.presentDomain - 2,
        domains: domainState.domains,
      };
      localStorage["domainState"] = JSON.stringify(domainStatee);
    }

    let apistate = localStorage["domainState"];

    if (apistate) {
      let domainState = JSON.parse(apistate);

      let apiCalledArray = this.state.alreadyCalled;
      if (false && apiCalledArray.includes(domainState.presentDomain)) {
        this.setState({
          resultFetching: false,
        });
      } else {
        this.setState({
          resultFetching: false,
        });
      }
    }
  };

  // Set slider value and note according to selected value from slider
  handleSliderChange = (e, data) => {
    let dummyVariable = this.state.questions;
    let dataWithKey;
    let rdata1 = dummyVariable;
    if (Object.keys(dummyVariable).length) {
      dataWithKey = rdata1.map((row1) => {
        let arr = [];
        row1.map((row3) => {
          if (row3.ID === data.ID) {
            row3.selectedAnswer = { Score: e / 10 };
          }
          /* push to arr*/ var element = {};
          var myProp = "ID";

          element.assessment = this.state.assessment_Id;
          element.assessmentResponseId = this.state.assessmentResponse.filter((f) => f.Assessment == this.state.assessment_Id && f.Question == row3.Question)[0].ID;
          element.questionId = row3.Question;

          element.score = row3.selectedAnswer.Score;
          element.answer = row3.selectedAnswer.Score * 10;

          arr.push(element);
        });
        this.setState({
          resultPassArray: arr,
        });
        return row1;
      });
    }
    this.getFinalEvaluation(false);
  };

  // Set text box value and note according to entered value
  handleTextBoxChange = (e, data) => {
    let dummyVariable = this.state.questions;
    let dataWithKey;

    let rdata1 = dummyVariable;
    if (Object.keys(dummyVariable).length) {
      dataWithKey = rdata1.map((row1) => {
        let arr = [];
        row1.map((row3) => {
          if (row3.ID === data.ID) {
            row3.selectedAnswer = { Score: e };
          }
          /* push to arr*/ var element = {};
          var myProp = "ID";
          element.assessment = this.state.assessment_Id;
          element.assessmentResponseId = this.state.assessmentResponse.filter((f) => f.Assessment == this.state.assessment_Id && f.Question == row3.Question)[0].ID;
          element.questionId = row3.Question;

          element.answer = row3.selectedAnswer.Score;
          element.score = row3.selectedAnswer.Score;
          element.note = {
            title: row3.selectedAnswer.Note_title,
            description: row3.selectedAnswer.Note,
          };
          arr.push(element);
        });

        this.setState({
          resultPassArray: arr,
          percentageIncomeField: e,
        });
        return row1;
      });
    }
  };

  async getFinalEvaluation(whenSubmitClicked) {
    let uri = this.url + "/assessment/check-spouse-assessment";

    let array = this.state.questions;
    let mergedArrays = new Array([].concat(...array));
    let obj = mergedArrays[0];

    let templateId = sessionStorage["templateId"];

    let coupleScores = null;

    let displayCoupleAssesmentMessage = false;

    // When Submit button click and assesments final page
    if (JSON.parse(localStorage["domainState"]).presentDomain === 7 && whenSubmitClicked === true) {
      let stateapp = localStorage["appState"];
      let AppStateCall = JSON.parse(stateapp);

      // If assessemnent is of couple type, then get couple scores
      if (templateId == 2) {
        const postData = {
          user_id: AppStateCall.user,
          assessment_id: this.state.resultPassArray[0].assessment,
          abc: "abc",
        };

        await axios.post(uri, postData).then((response) => {
          coupleScores = response.data.data;

          if (response.data.message === "No couple Assessment exists") {
            displayCoupleAssesmentMessage = true;
          }
        });
      }
    }

    let newState = null;

    let coupleObject = obj;

    // If assessemnent is of couple type and couple score has value
    if (coupleScores != null) {
      for (let i = 0; i < obj.length; i++) {
        let couple_note = null;
        let couple_note_title = null;
        let special_note = null;

        for (let j = 0; j < obj[i].answers.length; j++) {
          // Set notes of couple if either couple has low score.
          if (obj[i].selectedAnswer.Score <= obj[i].answers[j].Score || (Number(coupleScores[i].Score) <= obj[i].answers[j].Score && couple_note == null)) {
            couple_note = obj[i].answers[j].Note;
            couple_note_title = obj[i].answers[j].Note_title;
            special_note = obj[i].answers[j].Special_note;
            break;
          }
        }

        // Set average scores
        obj[i].selectedAnswer = {
          ...obj[i].selectedAnswer,
          ScoreFirstPersonCouple: obj[i].selectedAnswer.Score,
          Score: (obj[i].selectedAnswer.Score + Number(coupleScores[i].Score)) / 2,
          Note: couple_note,
          Note_title: couple_note_title,
          Special_note: special_note,
        };
      }
    }

    var holder = {};
    var notesholder = {};

    let dummyNotes = "";

    obj.forEach(function (d, i) {
      if (coupleScores != null) {
      }

      if (notesholder.hasOwnProperty(d.Question_Category)) {
        let dummy = notesholder[d.Question_Category];

        dummy.push(
          d.Question_type != 1
            ? {
                note: d.Note,
                title: d.Note_title,
                toDisplay: templateId == 1 ? d.selectedAnswer.Score >= d.Special_note_min && d.selectedAnswer.Score <= d.Special_note_max : coupleScores != null ? (obj[i].selectedAnswer.ScoreFirstPersonCouple >= d.Special_note_min && obj[i].selectedAnswer.ScoreFirstPersonCouple <= d.Special_note_max) || (coupleScores[i].Score >= d.Special_note_min && coupleScores[i].Score <= d.Special_note_max) : null,
              }
            : {
                note: d.selectedAnswer.Note,
                title: d.selectedAnswer.Note_title,
                toDisplay: !isNull(d.selectedAnswer.Special_note),
              },
        );
        notesholder[d.Question_Category] = dummy;
      } else {
        notesholder[d.Question_Category] = [
          d.Question_type != 1
            ? {
                note: d.Note,
                title: d.Note_title,
                toDisplay: templateId == 1 ? d.selectedAnswer.Score >= d.Special_note_min && d.selectedAnswer.Score <= d.Special_note_max : coupleScores != null ? (obj[i].selectedAnswer.ScoreFirstPersonCouple >= d.Special_note_min && obj[i].selectedAnswer.ScoreFirstPersonCouple <= d.Special_note_max) || (coupleScores[i].Score >= d.Special_note_min && coupleScores[i].Score <= d.Special_note_max) : null,
              } // change condition
            : {
                note: d.selectedAnswer.Note,
                title: d.selectedAnswer.Note_title,
                toDisplay: !isNull(d.selectedAnswer.Special_note), // change condition
              },
        ];
      }

      if (holder.hasOwnProperty(d.Question_Category)) {
        d.Question_type != 4
          ? (holder[d.Question_Category] = holder[d.Question_Category] + d.selectedAnswer.Score)
          : "array" in holder
          ? (holder["array"] = holder[array][d.Question_Category].push(d.selectedAnswer.Score))
          : (holder["array"] = {
              [d.Question_Category]: [d.selectedAnswer.Score],
            });
      } else {
        d.Question_type != 4
          ? (holder[d.Question_Category] = d.selectedAnswer.Score)
          : (holder["array"] = {
              [d.Question_Category]: [d.selectedAnswer.Score],
            });
      }
    });
    var obj2 = [];
    let dummyNote = [];

    for (var prop in holder) {
      obj2.push({
        Question_Category: prop,
        notes: notesholder,
        Score: has(holder.array, prop) ? round(holder[prop] / flattenDepth(array, 5).filter((obj) => obj.Question_Category === prop && obj.Question_type != 4).length, 2) * 0.1 * (holder["array"][prop][0] > 10 ? 10 : holder["array"][prop][0]) : round(holder[prop] / flattenDepth(array, 5).filter((obj) => obj.Question_Category === prop).length, 2),
      });
    }
    this.setState({
      result: obj2,
    });

    // true only when submit button click and couple has unmatched assessment, then show results.
    // If couple does not have completed and unmatched assesment then displayCoupleAssesmentMessage is true
    if (displayCoupleAssesmentMessage === false && whenSubmitClicked === true) {
      this.props.history.push("/result", {
        result: this.state.result.filter((item) => item.Question_Category != "array"),
        assessment_Id: this.state.assessment_Id,
        date_completed: moment().format("YYYY-MM-DD hh:mm:ss"),
      });
    } else if (displayCoupleAssesmentMessage === true && whenSubmitClicked === true) {
      this.saveAssessment();
      this.setState({
        popoverOpen: true,
      });
    }
  }

  saveAssessment() {
    let uri = this.url + "/assessment";

    const options = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
      },
    };
    let state = localStorage["appState"];
    let AppState = JSON.parse(state);
    const postData = {
      date_completed: moment().format("YYYY-MM-DD HH:mm:ss"),
      user_id: AppState.user,
      email: AppState.email,
      template: sessionStorage["templateId"],
      status: "completed",
      assessment_id: this.state.assessment_Id,
    };

    axios.put(uri, postData, options).then((response) => {
      if (response.status == 200) {
      }
    });
  }

  render() {
    let questionsArray = this.state.questions;
    let mergedQuestionsArrays = new Array([].concat(...questionsArray));
    let questionsArrayObject = mergedQuestionsArrays[0];

    let z = Object.keys(this.state.presentCategory).filter((i) =>
      i.includes(
        filter(JSON.parse(localStorage["domainState"]).domains, {
          ID: JSON.parse(localStorage["domainState"]).presentDomain,
        })[0].domain_name,
      ),
    );
    const handleChange = (selectedAnswer) => {
      let dummyVariable = this.state.questions;

      let dataWithKey;
      let rdata1 = dummyVariable;

      if (Object.keys(dummyVariable).length) {
        dataWithKey = rdata1.map((row1) => {
          let arr = [];
          row1.map((row3) => {
            if (row3.Question === selectedAnswer.Question) {
              row3.selectedAnswer = selectedAnswer;
            }

            if (row3.Question_type === 1) {
              var element = {};
              var myProp = "ID";

              element.assessment = this.state.assessment_Id;
              element.questionId = row3.Question;
              element.assessmentResponseId = this.state.assessmentResponse.filter((f) => f.Assessment == this.state.assessment_Id && f.Question == row3.Question)[0].ID;

              if (row3.selectedAnswer.hasOwnProperty(myProp)) {
                element.answer = row3.selectedAnswer.ID;
              } else {
                element.answer = 0;
              }

              element.score = row3.selectedAnswer.Score;
              element.note = {
                title: row3.selectedAnswer.Note_title,
                description: row3.selectedAnswer.Note,
              };
              arr.push(element);
            } else if (row3.Question_type === 2) {
              var element = {};
              var myProp = "ID";

              element.assessment = this.state.assessment_Id;
              element.assessmentResponseId = this.state.assessmentResponse.filter((f) => f.Assessment == this.state.assessment_Id && f.Question == row3.Question)[0].ID;
              element.questionId = row3.Question;

              element.score = row3.selectedAnswer.Score;
              element.answer = row3.selectedAnswer.Score * 10;

              arr.push(element);
            } else if (row3.Question_type === 4) {
              var element = {};
              var myProp = "ID";
              element.assessment = this.state.assessment_Id;
              element.assessmentResponseId = this.state.assessmentResponse.filter((f) => f.Assessment == this.state.assessment_Id && f.Question == row3.Question)[0].ID;
              element.questionId = row3.Question;

              element.answer = row3.selectedAnswer.Score;
              element.score = row3.selectedAnswer.Score;
              element.note = {
                title: row3.selectedAnswer.Note_title,
                description: row3.selectedAnswer.Note,
              };
              arr.push(element);
            }
          });
          this.setState({
            resultPassArray: arr,
          });

          return row1;
        });
      }
      this.getFinalEvaluation(false);
    };

    return (
      <div>
        <Navigation {...this.props} />

        <div className="height">
          <div className="container">
            <Modal isOpen={this.state.popoverOpen}>
              <ModalBody>You have completed a Couples Assessment. You will receive a feedback report after your spouse has completed a Couples Assessment within the next 30 days.</ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={() => this.props.history.push("/home")}>
                  Home
                </Button>
              </ModalFooter>
            </Modal>
            {this.state.resultFetching ? (
              <div style={{ marginLeft: "400px" }}>
                <ClipLoader size={300} color={"#123abc"} loading={true} />
              </div>
            ) : (
              <div className="assessment-worship-bottom">
                <h3 className="domainName">
                  {
                    filter(JSON.parse(localStorage["domainState"]).domains, {
                      ID: JSON.parse(localStorage["domainState"]).presentDomain,
                    })[0].domain_name
                  }
                </h3>
                {Object.keys(this.state.presentCategory).length &&
                  z.map((row1) => (
                    <div>
                      <h3 className="cetagoryName">{row1.replace(/_+/g, " ")}</h3>
                      {this.state.presentCategory[row1].map((row, index) => (
                        <div className="assessment-worship-box">
                          <p>
                            <span>{row.Sequence}. </span> {row.Question_text}
                          </p>

                          <ul className="radio-style">
                            {row.Question_type == 1 ? (
                              row.answers.map((row2, index2) => (
                                <li>
                                  <div className="custom-control custom-radio">
                                    <input type="radio" required={true} className="custom-control-input" id={[row1] + row.ID + "_" + index2} name={[row1] + row.ID + index} onChange={() => handleChange(row2)} checked={questionsArrayObject.find((x) => x.ID === row.ID).selectedAnswer.ID === row2.ID} />
                                    <label className="custom-control-label" htmlFor={[row1] + row.ID + "_" + index2}>
                                      {row2.Possible_Answer}
                                    </label>
                                  </div>
                                </li>
                              ))
                            ) : row.Question_type == 2 ? (
                              <div>
                                <Slider
                                  min={0}
                                  max={100}
                                  defaultValue={questionsArrayObject.find((x) => x.ID === row.ID).selectedAnswer.Score * 10}
                                  marks={{
                                    0: 0,
                                    10: 10,
                                    20: 20,
                                    30: 30,
                                    40: 40,
                                    50: 50,
                                    60: 60,
                                    70: 70,
                                    80: 80,
                                    90: 90,
                                    100: 100,
                                  }}
                                  step={10}
                                  onChange={(e) => this.handleSliderChange(e, row)}
                                />
                              </div>
                            ) : (
                              <FormGroup>
                                <Label for="exampleEmail"></Label>
                                <Input type="number" name="percentage" id="exampleEmail" min="1" max="100" value={this.state.percentageIncomeField} onChange={(e) => this.handleTextBoxChange(document.getElementById("exampleEmail").value, row)} placeholder="%" />
                              </FormGroup>
                            )}
                          </ul>
                        </div>
                      ))}
                    </div>
                  ))}
              </div>
            )}
          </div>

          <div className="centerButtons text-center">
            {
              /*JSON.parse(localStorage["domainState"]).presentDomain > 1*/ true && (
                <button
                  className="btn btn-small cta-primary prev-btn"
                  style={{
                    pointerEvents: JSON.parse(localStorage["domainState"]).presentDomain > 1 ? "auto" : "none",
                  }}
                  onClick={() => {
                    this.Prev();
                  }}
                >
                  Previous
                </button>
              )
            }
            {JSON.parse(localStorage["domainState"]).presentDomain < 7 && (
              <button
                className="btn btn-small cta-primary next-btn"
                onClick={() => {
                  this.getFinalEvaluation(false);
                  this.Next();
                }}
              >
                Next
              </button>
            )}
            {JSON.parse(localStorage["domainState"]).presentDomain === 7 && (
              <button
                className="btn btn-small cta-primary prev-btn"
                onClick={() => {
                  this.getFinalEvaluation();
                  let uri = this.url + "/assessment/add-assessment";
                  const postData = this.state.resultPassArray;
                  const finalPostData = {
                    saveData: postData.map(({ assessmentResponseId, assessment, questionId, answer, score }) => ({
                      assessmentResponseId,
                      assessment,
                      questionId,
                      answer,
                      score,
                    })),
                    authToken: this.props.authToken,
                  };
                  const options = {
                    headers: {
                      "Content-Type": "application/json",
                      "Access-Control-Allow-Origin": "*",
                      "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                    },
                  };

                  axios.post(uri, finalPostData).then((response) => {});
                }}
              >
                <Link
                  to={{
                    pathname: "/result",
                    state: {
                      result: this.state.result.filter((item) => item.Question_Category != "array"),
                      assessment_Id: Number(this.state.resultPassArray[0].assessment),
                    },
                  }}
                  style={{ color: "white" }}
                >
                  Submit
                </Link>
              </button>
            )}

            {
              <button
                className="btn btn-small cta-primary prev-btn"
                style={{ marginLeft: "15px" }}
                onClick={() => {
                  this.getFinalEvaluation();
                  let uri = this.url + "/assessment/add-assessment";
                  const postData = this.state.resultPassArray;

                  const finalPostData = {
                    saveData: postData.map(({ assessmentResponseId, assessment, questionId, answer, score }) => ({
                      assessmentResponseId,
                      assessment,
                      questionId,
                      answer,
                      score,
                    })),
                    authToken: this.props.authToken,
                  };

                  const options = {
                    headers: {
                      "Content-Type": "application/json",
                      "Access-Control-Allow-Origin": "*",
                      "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                    },
                  };

                  !isEmpty(finalPostData) && axios.post(uri, finalPostData).then((response) => {});
                }}
              >
                <Link
                  to={{
                    pathname: "/home",
                  }}
                  style={{ color: "white" }}
                >
                  Save & Exit
                </Link>
              </button>
            }
          </div>
        </div>
      </div>
    );
  }
}
