import axios from "axios";
import $ from "jquery";
import React, { Component } from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";

export default class LinkSpouse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailError: "",
      passwordError: "",
      email: "",
      password: "",
      popoverOpen: false,
      spouse_id: this.props.match.params.spouse_id,
      subscription: this.props.match.params.subscription,
    };
    //this.url = "http://localhost:33333";
    this.url = `${process.env.REACT_APP_API_IP}`;
  }

  componentDidMount() {
    $(".App-header").hide();

    if (this.props.match.params.subscription != "00") {
      const postData = {
        user_id: this.props.match.params.user_id,
        spouse_id: this.props.match.params.spouse_id,
      };
      const options = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
        },
      };

      let uri = this.url + "/profile/linkSpouse";

      axios
        .put(uri, postData, options)

        .then((response) => {
          if (response.status == 200) {
            this.setState({
              popoverOpen: true,
            });
          }
          console.log("response", response);
        });
    } else {
      console.log("b");
      const postData = {
        user_id: this.props.match.params.user_id,
        spouse_id: this.props.match.params.spouse_id,
      };
      const options = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
        },
      };

      let uri = this.url + "/profile/shareSubscription";

      axios
        .put(uri, postData, options)

        .then((response) => {
          if (response.status == 200) {
            this.setState({
              popoverOpen: true,
            });
          }
        });
    }
  }

  render() {
    return (
      <div>
        <section className="main-cover">
          <div className="container-fluid custom-container">
            <Modal isOpen={this.state.popoverOpen}>
              <ModalBody>
                {this.state.subscription != "00"
                  ? `Congratulations! Your account has been linked with your spouse.
                You may now take couple's assessments.`
                  : `Congratulations! You joined subscription of your spouse.`}
              </ModalBody>
              <ModalFooter>
                <Button
                  color="secondary"
                  onClick={() => this.props.history.push("/login")}
                >
                  Login
                </Button>
              </ModalFooter>
            </Modal>
            <div className="card-inner">
              <div className="row">
                <div className="col-xl-8 col-lg-7 order-lg-1 order-2">
                  <div className="text-cover">
                    <div>
                      <a href="#" className="brand-name">
                        <img src="/static/media/logo.3c835923.png" alt="" />
                      </a>
                      <h1>Tools for a Healthier You</h1>
                      <p>
                        The Essential Faith Project assists churches and
                        Christian organizations in making, assessing and
                        strengthening disciples of Jesus. Our primary tool is
                        our Spiritual Health Assessment which profiles a
                        person’s level of spiritual well-being across 7
                        essential domains.
                      </p>
                    </div>
                    <div className="cover-footer">
                      <a href="/privacy">Privacy Policy</a>
                      <a href="/terms">Terms of Service</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
