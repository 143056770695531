import React, { Component } from "react";
import $ from "jquery";
import axios from "axios";

import logo from "../imgs/logo.png";
import dots from "../imgs/dots.png";
import Checked from "../imgs/checked.png";
import SmallLogo from "../imgs/SmallLogo.png";

/* global variable */
var i = 1;
var alldomainLimit = 0;
export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allDomain: [],
      presentDomain: " ",
      nextDomain: " ",
      domainLimit: 0,
    };
    this.url = `${process.env.REACT_APP_API_IP}`;
  }

  componentWillMount() {
    let uri = this.url + "/domains";
    axios
      .post(uri, { authToken: this.props.authToken })

      .then((response) => {
        if (response.status == 200) {
          this.setState({
            allDomain: response.data,
            domainLimit: response.data.length,
          });

          alldomainLimit = response.data.length;

          $(".li_0").addClass("active");

          let domainState = {
            presentDomain: response.data[0].ID,
            nextDomain: response.data[1].ID,
            previousDomain: "",
            domains: response.data,
          };

          localStorage["domainState"] = JSON.stringify(domainState);
        }
      });
  }
  componentDidMount() {
    $(".next-btn").click(function () {
      $(".assessment-worship-list ul li")
        .next(".li_" + i)
        .toggleClass("active");
      $(".assessment-worship-list ul li")
        .next(".li_" + i)
        .prev()
        .toggleClass("img");
      $(".assessment-worship-list ul li")
        .next(".li_" + i)
        .children()
        .children()
        .children(".dot-img")
        .attr("src", dots);
      i++;
    });

    $(".prev-btn").click(function () {
      var count = i - 1;
      $(".assessment-worship-list ul li")
        .prev(".li_" + count)
        .removeClass("active");
      $(".assessment-worship-list ul li")
        .next(".li_" + count)
        .prev()
        .removeClass("img");
      $(".assessment-worship-list ul li")
        .next(".li_" + count)
        .children()
        .children()
        .children(".dot-img")
        .removeAttr("src", dots);
      i--;
    });
  }

  render() {
    return (
      <div>
        <ul className="topnav">
          <li className="right">
            <a href="/login">Logout</a>
          </li>
          <li className="right">
            <a href="/profile">Profile</a>
          </li>
        </ul>
        <header className="page-header">
          <div className="container-fluid">
            <div className="nav-wrapper">
              <div className="main-header">
                <div className="brand-holder">
                  <img src={logo} alt="" />
                </div>
              </div>
            </div>
          </div>
        </header>

        <div className="assessment-worship-top">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="assessment-worship-list">
                  <ul>
                    {this.state.allDomain.map((row, index) => (
                      <li className={`${"li_" + index}`} key={"ka-" + index}>
                        <a>
                          <p>{row.domain_name}</p>
                          <span className="dot-img">
                            <img src={dots} alt="" />
                          </span>
                          <span className="checked-img">
                            <img src={Checked} alt="" />
                          </span>
                        </a>
                      </li>
                    ))}

                    <li className="li_7">
                      <a>
                        <p>Result</p>
                        <span>
                          <img src={SmallLogo} alt="" />
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
