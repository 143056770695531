import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch, useHistory } from "react-router-dom";
import Assessment from "./screens/Assessment";
import CompletedAssessment from "./screens/CompletedAssessment";
import ForgotPassword from "./screens/ForgotPassword";
import Home from "./screens/Home";
import LinkSpouse from "./screens/LinkSpouse";
import Login from "./screens/Login";
import MemberPayRegister from "./screens/MemberPayRegister";
import MinistryPaySignup from "./screens/MinistryPaySignup";
import MinistryRegister from "./screens/MinistryRegister";
import PendingAssessment from "./screens/PendingAssessment";
import Profile from "./screens/Profile";
import ResetPassword from "./screens/ResetPassword";
import ResultCard from "./screens/ResultCard";
import Signup from "./screens/Signup";
import SpouseSignUp from "./screens/SpouseSignUp";
import moment from "moment";
import Cookie from "js-cookie";

function App(props) {
  const history = useHistory();
  const [authToken, setAuthToken] = useState(Cookie.get("efp-x-access-token"));
  const [tokenExpirationDate, setTokenExpirationDate] = useState(Cookie.get("efp-x-access-token-expiration"));
  props = {
    ...history,
    ...props,
    authToken: authToken,
  };
  useEffect(() => {
    let checkTokenExpiration = setInterval(() => {
      if (tokenExpirationDate && moment(tokenExpirationDate).diff(moment(), "seconds") <= 0) {
        Cookie.remove("efp-x-access-token");
        Cookie.remove("efp-x-access-token-expiration");
        setTokenExpirationDate(null);
        history.push("/");
        setAuthToken(null);
      }
    }, 5000);
    return () => {
      clearInterval(checkTokenExpiration);
    };
  }, [tokenExpirationDate, history]);
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route
            exact
            path="/login"
            render={(inherited) => {
              return <Login {...props} {...inherited} {...inherited} setAuthToken={setAuthToken} />;
            }}
          />
          <Route
            exact
            path="/"
            render={(inherited) => {
              return <Signup {...props} {...inherited} />;
            }}
          />
          <Route
            path="/Assessment"
            render={(inherited) => {
              return <Assessment {...props} {...inherited} />;
            }}
          />
          <Route
            path="/CompletedAssessment"
            render={(inherited) => {
              return <CompletedAssessment {...props} {...inherited} />;
            }}
          />
          <Route
            path="/PendingAssessment"
            render={(inherited) => {
              return <PendingAssessment {...props} {...inherited} />;
            }}
          />
          <Route
            path="/ministry-signup"
            render={(inherited) => {
              return <MinistryRegister {...props} {...inherited} />;
            }}
          />
          <Route
            path="/home"
            render={(inherited) => {
              return <Home {...props} {...inherited} />;
            }}
          />
          <Route
            path="/result"
            render={(inherited) => {
              return <ResultCard {...props} {...inherited} />;
            }}
          />
          <Route
            path="/ministry-pay-signup/:token"
            render={(inherited) => {
              return <MinistryPaySignup {...props} {...inherited} />;
            }}
          />
          <Route
            path="/member-pay-signup/:token"
            render={(inherited) => {
              return <MemberPayRegister {...props} {...inherited} />;
            }}
          />
          <Route
            path="/forgot-password"
            render={(inherited) => {
              return <ForgotPassword {...props} {...inherited} />;
            }}
          />
          <Route
            path="/reset-password/:reset_token"
            render={(inherited) => {
              return <ResetPassword {...props} {...inherited} />;
            }}
          />
          <Route
            path="/profile"
            render={(inherited) => {
              return <Profile {...props} {...inherited} />;
            }}
          />
          <Route
            path="/link-spouse/:user_id/:spouse_id/:subscription"
            render={(inherited) => {
              return <LinkSpouse {...props} {...inherited} />;
            }}
          />

          <Route
            path="/spouse-sign-up/:user_id"
            render={(inherited) => {
              return <SpouseSignUp {...props} {...inherited} />;
            }}
          />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
